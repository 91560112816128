import React from "react"
import GlobeIcon from '../images/globe-icon';

import '../css/section-hero.css';


const SectionHero = ({ section, title, header}) => {

  const sectionMap = {
    landing: "",
    solutions: "Solutions",
    serve: "Who We Serve",
    resource: "Resource Center",
    claim: "Claims Support",
    about: "About",
    other: "",
  };
  
  const sectionName = sectionMap[section] || "";  // Default to the original value if not found
  return (
    <div className={`sectionHero hero-${section}`}>
          <div className="text-wrapper">
            <h3 className="superHL">{sectionName}</h3>        
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <div className="hero-icon-wrapper">
            <GlobeIcon />
          </div>
    </div>
  )
}

export default SectionHero