import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import "@babel/polyfill";

import Layout from "../layouts";
import Head from "../components/head";
import DefaultHero from "../components/default-hero";
import SectionHero from "../components/section-hero";

import "../css/glossary.css";

// Functional Glossary Component
const Glossary = ({ glossary }) => {
  const [activeLetter, setActiveLetter] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Generate alphabet links
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  // Filter glossary items by active letter or search input
  const filteredGlossary = glossary.filter((item) => {
    const term = item.term.toLowerCase();
    const isByLetter = activeLetter
      ? term.startsWith(activeLetter.toLowerCase())
      : true;

    const isBySearch =
      searchInput.trim() !== ""
        ? new RegExp(`\\b${searchInput.toLowerCase()}`).test(term)
        : true;

    return isByLetter && isBySearch;
  });

  // Sort filtered glossary items alphabetically
  const sortedGlossary = filteredGlossary.sort((a, b) =>
    a.term.toLowerCase().localeCompare(b.term.toLowerCase())
  );

  // Handle Search Input Change
  const handleSearchChange = (e) => {
    const input = e.target.value.toLowerCase();
    setSearchInput(input);

    // Generate predictive suggestions
    if (input.trim() !== "") {
      const matchedSuggestions = glossary
        .filter((item) =>
          new RegExp(`\\b${input}`).test(item.term.toLowerCase())
        )
        .map((item) => item.term);
      setSuggestions(matchedSuggestions.slice(0, 5)); // Limit suggestions to 5
    } else {
      setSuggestions([]);
    }

    setActiveLetter(""); // Reset active letter when searching
  };

  // Handle Suggestion Click
  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setSuggestions([]);
  };

  return (
    <div className="glossary-function">
        {/* Search Input with Suggestions */}
        <div className="search-container">
  <div className="search-input-wrapper">
    <input
      type="text"
      placeholder="Search terms..."
      value={searchInput}
      onChange={handleSearchChange}
      className="search-input"
    />
    {searchInput && (
      <button
        className="clear-button"
        onClick={() => {
          setSearchInput(""); // Clear the search input
          setSuggestions([]); // Clear suggestions
        }}
      >
        ✕
      </button>
    )}
  </div>
  {suggestions.length > 0 && (
    <ul className="suggestions-list">
      {suggestions.map((suggestion, index) => (
        <li
          key={index}
          onClick={() => handleSuggestionClick(suggestion)}
          className="suggestion-item"
        >
          {suggestion}
        </li>
      ))}
    </ul>
  )}
</div>

      {/* Alphabet Filter Links */}
      <div className="alphabet-links">
        {alphabet.map((letter) => (
          <button
            key={letter}
            className={`alphabet-link ${
              activeLetter === letter ? "active" : ""
            }`}
            onClick={() => {
              setActiveLetter(letter); // Set the active letter
              setSearchInput(""); // Clear the search input
              setSuggestions([]); // Clear suggestions
            }}
          >
            {letter}
          </button>
        ))}
        <button
          className={`alphabet-link ${activeLetter === "" ? "active" : ""}`}
          onClick={() => {
            setActiveLetter(""); // Clear active letter (show all)
            setSearchInput(""); // Clear the search input
            setSuggestions([]); // Clear suggestions
          }}
        >
          All
        </button>
      </div>

      {/* Glossary Items */}
      <div className="baseContainer">
        <p>
          Navigating Logistics and Supply Chain Terminology can be confusing. To
          help guide you, below is a list of acronyms, terms, words, and phrases
          to help clarify and define many of the things you may encounter.
        </p>
        {sortedGlossary.length > 0 ? (
          sortedGlossary.map((item, index) => (
            <div
              key={index}
              className={`glossary-item ${index % 2 === 0 ? "even" : "odd"}`}
            >
              <h3 className="term">{item.term}</h3>
              <div
                className="definition"
                dangerouslySetInnerHTML={{ __html: item.definition }}
              />
              <small>{item.category}</small>
            </div>
          ))
        ) : (
          <p>No terms found.</p>
        )}
      </div>
    </div>
  );
};

class PageTemplate extends Component {
  render() {
    const page = this.props.data.wpPage;
    const glossaryData =
      this.props.data.wp.acfOptionsGlossary.glossary.definition;
    const { title, seo } = page;
    const disclaimer = this.props.data.wp.acfOptionsGlossary.glossary.disclaimer;

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <SectionHero section="resource" title={page.title}/>


        <div className="container baseContainerWrapper glossary-wrapper">
          <div className="glossary-header">
            <h3 className="super">Resource Center</h3>
            <h2 className="headline">Search for glossary term</h2>
          </div>

          {/* Glossary Component */}
          <Glossary glossary={glossaryData} />
          <div className="disclaimer" dangerouslySetInnerHTML={{__html: disclaimer }} />
        </div>
      </Layout>
    );
  }
}

export default PageTemplate;

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      uri
      seo {
        title
        metaDesc
      }
    }
    wp {
      acfOptionsGlossary {
        glossary {
          disclaimer
          definition {
            term
            category
            definition
          }
        }
      }
    }
  }
`;
